import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {QuestionContext} from '../store/context/questionnaire-context';
import { testRecommendations } from '../services/data';
import { submitButtonStyles } from '../styles/mui_custom';
import { styled } from '@mui/material/styles';
import { Button, Modal } from '@mui/material';
import Reward from '../components/Reward';

function Recommendations () {
  const [reward, setReward] = useState({
    show: false,
    data: null, // an object of recommendation data
  });

  const qContext = useContext(QuestionContext);
  const {userId, recommendations} = qContext;

  const navigate = useNavigate();

  useEffect(() => {
    // If no user ID is saved in state, redirect to the initial view
    if (!userId) {
      navigate('/');
      return;
    }
  }, []); // eslint-disable-line

  // DEV ONLY: Test recommendation data for testing
  // const recommendations = [
  //   ...testRecommendations
  // ];

  const handleRewardButton = (data) => {
    setReward({
      show: true,
      data,
    });
  };

  const handleClose = () => {
    setReward({
      show: false,
      data: null,
    });
  };

  const StyledButton = styled(Button)(() => (submitButtonStyles));

  return (
    <div className="view_container">
      <div className="content_container">
        <p className="recommendations_heading">
          Welcome To Your Recommended Spaces
        </p>
        <div>
          {recommendations.length === 0 && (
            <p className="no_recommendations_message">
              No recommendations available.
            </p>
          )}
          {recommendations.map((data) => (
            <div key={data.id} className="recommendation_card_container">
              {data.featuredImageUrl && (
                <img src={data.featuredImageUrl} className="recommendation_card_image" alt="" />
              )}
              <p className="recommendation_card_category">
                {data.category.name}
              </p>
              <p className="recommendation_card_title">
                {data.name}
              </p>
              {data.description && (
                <p className="recommendation_card_text">
                  {data.description}
                </p>
              )}
              {data.reward && (
                <div className="recommendation_card_button">
                  <StyledButton
                    onClick={() => handleRewardButton(data)}
                  >
                    Claim Reward
                  </StyledButton>
                </div>
              )}
            </div>
          ))}
          <Modal
            open={reward.show}
            onClose={handleClose}
          >
            <div className="reward_modal">
              <div className="reward_container">
                <Reward data={reward.data} />
                <StyledButton
                  onClick={handleClose}
                >
                  Close
                </StyledButton>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Recommendations;
/**
 * Acquire an array of numerical years ending with the current year
 * @param {number} startYear
 * @return {number[]}
 */
export const getYears = (startYear) => {
  const years = [];
  const start = Math.trunc(startYear);
  const currentYear = new Date().getFullYear();

  if (start > currentYear) {
    return years;
  }

  for (let i = currentYear; i >= start; i--) {
    years.push(i);
  }

  return years;
};

/**
 * Determine if app is in dev mode
 * Checks to see if the base URL includes 'localhost' or 'kikcit-c392d'
 * @return {boolean}
 */
export const checkDevMode = () => {
  const url = window.location.href;
  const { hostname } = new URL(url);
  return (hostname.includes('localhost') || hostname.includes('kikcit-c392d'));
};

import {initializeApp} from 'firebase/app';
import {getDatabase} from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyDwuWH3q_a6xI9ilZZfKzSEng6YgE7BHUg",
  authDomain: "kikcit-c392d.firebaseapp.com",
  databaseURL: "https://kikcit-c392d-default-rtdb.firebaseio.com",
  projectId: "kikcit-c392d",
  storageBucket: "kikcit-c392d.appspot.com",
  messagingSenderId: "298354598481",
  appId: "1:298354598481:web:93e9905f09d64716050e70",
};

// Initialize Firebase
export const fbApp = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
export const realtimeDatabase = getDatabase(fbApp);

export const brandCategories = [
  'Food + Beverage',
  'Arts + Entertainment',
  'Education + Wellness',
  'Sports + Entertainment'
];

export const primaryBrandColors = [
  'Pink',
  'Red',
  'Brown',
  'Orange',
  'Yellow',
  'Green',
  'Blue',
  'Purple',
  'Grey',
  'Black',
];

export const brandAesthetics = [
  'Visually-Rich',
  'Music-Friendly',
  'Socially Diverse',
];

export const inputLabelProps = {
  shrink: true,
  sx: {
    textTransform: 'uppercase',
    color: '#ff7629'
  }
}

export const businessCategories = [
  {
    id: '194e0593-2096-497d-a9b7-eeb73c585dba',
    name: 'Food + Bev',
  },
  {
    id: '3dbc9c8e-ab51-427c-98fd-e0256a5f34d8',
    name: 'Art + Ent',
  },
  {
    id: '67ec56f5-c6b4-4503-a512-5af2c4564c4a',
    name: 'Wellness + Edu',
  },
  {
    id: 'a48abc76-5d11-4f12-add1-b07107fdf475',
    name: 'Leisure + Joy',
  },
];

export const testRecommendations = [
  {
    category: {
      id: '194e0593-2096-497d-a9b7-eeb73c585dba',
      name: 'Food + Bev',
    },
    description: "Vietnamese coffee, worldly teas, and inspired flavors.",
    featuredImageUrl: 'https://firebasestorage.googleapis.com/v0/b/kikcit-c392d.appspot.com/o/businesses%2FThe%20Mortals%20Cafe%2FFeatured%20Image.png?alt=media&token=fb7267cc-cdb3-45f2-9a3b-b2f965b85652',
    id: '5acde3e7-b167-4440-a6d6-7c7db38772bf',
    location: '111 Division Ave S, Grand Rapids, MI 49503',
    match: 0.5454545454545454,
    name: 'The Mortals Cafe + Confection',
    reward: {
      active: true,
      businessId: '5acde3e7-b167-4440-a6d6-7c7db38772bf',
      description: '10% off any drink in store',
      id: 'd25f0d3c-e05e-4d5d-853f-bc9d0ff1c264',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/kikcit-c392d.appspot.com/o/rewards%2FThe%20Mortals%20Cafe%2FReward.png?alt=media&token=75baadf8-4e4e-4df8-8271-4602bd53589c',
    },
  },
  {
    category: {
      id: '67ec56f5-c6b4-4503-a512-5af2c4564c4a',
      name: 'Wellness + Edu',
    },
    description: "Your destination for cold-pressed juices, organic smoothies, & healthy snacks.",
    featuredImageUrl: 'https://firebasestorage.googleapis.com/v0/b/kikcit-c392d.appspot.com/o/businesses%2FGood%20Judy%2FFeatured%20Image.png?alt=media&token=174ae114-bf98-4e86-b26c-a311cae4d927',
    id: '5861cb52-c7a0-430c-a925-aae015db93ee',
    location: '1146 Wealthy St SE, Grand Rapids, MI 49506',
    match: 0.45454545454545453,
    name: 'Good Judy\'s Market & Juice Bar',
    reward: {
      active: true,
      businessId: '5861cb52-c7a0-430c-a925-aae015db93ee',
      description: '15% off any drink in store',
      id: '51d42bc1-508e-4502-8adb-0b34bec73809',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/kikcit-c392d.appspot.com/o/rewards%2FGood%20Judy%2FReward.png?alt=media&token=82ac1a69-6150-4733-8b65-672c0c0d1caa',
    },
  },
];

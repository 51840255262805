import { createContext, useState } from "react";

export const QuestionContext = createContext({
  userId: null, // UUIDv4 string ID returned by server
  updateUserId: () => {},
  answers: [], // An array of objects { qid: string, aid: string }
  initializeAnswers: () => {},
  updateAnswer: () => {},
  recommendations: [], // An array of objects { id: string, name: string, match: number, category: { id: string, name: string } }
  updateRecommendations: () => {},
});

function QuestionContextProvider({ children }) {
  const [userId, setUserId] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [recommendations, setRecommendations] = useState([]);

  // Update the user ID of the person filling out the quiz
  const updateUserId = (id) => {
    setUserId(id);
  };

  // Add an array of answer data sourced from the database
  const initializeAnswers = (data) => {
    setAnswers(data);
  };

  // Update an answer by ID with a corresponding answer ID
  const updateAnswer = (qid, aid) => {

    const answerUpdate = [
      ...answers,
    ];

    const matchId = answerUpdate.findIndex((answer) => {
      return answer.qid === qid;
    });

    const newData = {
      qid, aid
    };

    // If existing question data is found, replace it; otherwise add new data
    if (matchId > -1) {
      answerUpdate.splice(matchId, 1, newData);
    } else {
      answerUpdate.push(newData);
    }

    setAnswers(answerUpdate);
  };

  // Setter for recommendations
  const updateRecommendations = (data) => {
    setRecommendations(data);
  };

  const value = {
    userId,
    updateUserId,
    answers,
    initializeAnswers,
    updateAnswer,
    recommendations,
    updateRecommendations,
  };

  return (
    <QuestionContext.Provider value={value}>
      {children}
    </QuestionContext.Provider>
  );
}

export default QuestionContextProvider;

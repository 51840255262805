import {getFunctions, httpsCallable} from 'firebase/functions';

/**
 * Save basic business data to database
 * Returns { id: string, inviteUrl: string | null } or null if an error occurs
 */
export const saveBusinessData = async ({
  token, name, email, categoryId, featuredImageUrl, description, location, devMode
}) => {
  const functions = getFunctions();
  const saveBusinessUserData = httpsCallable(functions, 'saveBusinessUserData');

  try {
    const {data} = await saveBusinessUserData({
      token, name, email, categoryId, featuredImageUrl, description, location, devMode
    });
    return data;
  } catch (error) {
    console.log('Failed to save user data: ', error);
    // TODO: Move to branded error message using Material UI
    window.alert('Could not save data at this time.  Please check your network connection or try again later.');
    return null;
  }
};

/**
 * Save reward data to database
 * Returns { success: true } or null if an error occurs
 */
export const saveRewardData = async ({
  token, description, email, imageUrl, devMode
}) => {
  const functions = getFunctions();
  const saveBusinessRewardData = httpsCallable(functions, 'saveBusinessRewardData');

  try {
    const {data} = await saveBusinessRewardData({
      token, description, email, imageUrl, devMode
    });
    return data;
  } catch (error) {
    console.log('Failed to save reward data: ', error);
    // TODO: Move to branded error message using Material UI
    if (error.code === 'functions/not-found') {
      window.alert('Could not locate a valid business for this email address.');
    } else {
      window.alert('Could not save data at this time.  Please check your network connection or try again later.');
    }
    return null;
  }
};

/**
 * Get business categories from the database
 * Returns an array of documents { id: string, name: string }
 * or null if an error occurs
 */
export const getBusinessCategories = async () => {
  const functions = getFunctions();
  const getBusinessCategories = httpsCallable(functions, 'getBusinessCategories');

  try {
    const {data} = await getBusinessCategories();
    return data;
  } catch (error) {
    console.log('Failed to retrieve business categories: ', error);
    // TODO: Move to branded error message using Material UI
    window.alert('Could not load data at this time.  Please check your network connection or try again later.');
    return null;
  }
};

/**
 * Verify business by email and temp access code
 * Returns an object of business user data
 * { id: string, categoryId: string, name: string } as well as 
 * an array of business category documents
 * { id: string, name: string }
 */
export const verifyBusinessByEmailAndCode = async ({
  email, code
}) => {
  const functions = getFunctions();
  const verifyBusiness = httpsCallable(functions, 'verifyBusiness');
  const getBusinessCategories = httpsCallable(functions, 'getBusinessCategories');

  let businessData, businessCategories;

  try {
    const {data} = await verifyBusiness({email, code});
    businessData = data;
  } catch (error) {
    console.log('Failed to retrieve business data: ', error);
    // TODO: Move to branded error message using Material UI
    if (error.code === 'functions/permission-denied') {
      window.alert('Incorrect or expired access code.');
    } else {
      window.alert('Could not load data at this time.  Please check your network connection or try again later.');
    }
    return null;
  }

  try {
    const {data} = await getBusinessCategories();
    businessCategories = data;
  } catch (error) {
    console.log('Failed to retrieve business categories: ', error);
    // TODO: Move to branded error message using Material UI
    window.alert('Could not load data at this time.  Please check your network connection or try again later.');
    return null;
  }

  return ({
    businessData, businessCategories
  });
};
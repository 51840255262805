import { useContext, useState, useEffect } from "react";
import { QuestionContext } from "../store/context/questionnaire-context";
import Question from "../Question";
import { getQuizData, submitConsumerQuizData } from "../services/quiz";
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';
import { submitButtonStyles } from '../styles/mui_custom';

function Questionnaire() {
  //const [reviewMode, setReviewMode] = useState(false)
  const [quizData, setQuizData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState({});

  const qContext = useContext(QuestionContext);
  const {initializeAnswers, userId, updateRecommendations} = qContext;
  const savedAnswers = qContext.answers;

  const navigate = useNavigate();

  // Load questionnnaire data on mount
  useEffect(() => {
    // If no user ID is saved in state, redirect to the initial view
    if (!userId) {
      navigate('/');
      return;
    }

    // Fetch quiz from database
    const quizType = 'Consumer';
    getQuizData({quizType})
    .then((data) => {
      if (data) {
        // Update context
        const initialAnswers = data.map((question) => {
          return {qid: question.id, aid: ''}
        });
        initializeAnswers(initialAnswers);
      }
      // Set local state
      setQuizData(data);
      setIsLoading(false);
    });
  }, []); // eslint-disable-line

  const onAnswerChanged = ({qid, aid}) => {
    if (aid && error[qid]) {
      setError((prevState) => {
        const errorUpdate = prevState;
        errorUpdate[qid] = false;
        return errorUpdate;
      });
    }
  };

  const handleSubmit = async () => {
    // Make sure all answers are in place
    let errorsExist = false;

    const answerErrors = {};
    savedAnswers.forEach((answer) => {
      if (!answer.aid) {
        answerErrors[answer.qid] = true;
        if (!errorsExist) {
          errorsExist = true;
        }
      }
    });

    setError((prevState) => ({
      ...prevState,
      ...answerErrors,
    }));

    if (errorsExist) {
      window.alert("Please complete each item before continuing.");
      return;
    }

    setIsSubmitting(true);

    const submissionResponse = await submitConsumerQuizData({
      userId,
      responses: savedAnswers,
    });

    if (submissionResponse?.recommendations) {
      updateRecommendations(submissionResponse.recommendations);
      navigate("/results");
      window.scrollTo(0, 0);
    }

    setIsSubmitting(false);
  }

  const SubmitButton = styled(LoadingButton)(() => (submitButtonStyles));

  const loadingSpinner = (
    <CircularProgress 
      size={30}
      sx={{
        color: '#ff7629'
      }}
    />
  );

  const submitSpinner = (
    <CircularProgress 
      size={20}
      sx={{
        color: 'white'
      }}
    />
  );

  return (
    <div className="view_container">
      <div className="content_container">
        <p className="questionnaire_heading">
          Welcome to<br />Check Your Vibe
        </p>
        {isLoading && (
          <div className="loading_indicator">
            {loadingSpinner}
          </div>
        )}
        {!isLoading && !quizData && (
          <p className="loading_message">
            Quiz unavailable; please try again later.
          </p>
        )}
        {!isLoading && quizData && (
          <>
            <div className="form_container">
              {quizData.map((question) => {
                return <Question
                  id={question.id}
                  key={question.id}
                  number={question.order}
                  question={question.text}
                  answers={question.answers}
                  error={error[question.id]}
                  onAnswerChanged={onAnswerChanged}
                />
              })}
            </div>
            <div className="submit_button_container">
              <SubmitButton
                onClick={handleSubmit}
                loading={isSubmitting}
                disabled={isSubmitting}
                loadingIndicator={submitSpinner}
              >
                Submit
              </SubmitButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Questionnaire;

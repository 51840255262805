import "./store/firebase";

import Questionnaire from "./Questionnaire";
import NameEmail from "./NameEmail";
import Recommendations from "./Recommendations";
import Complete from "./Complete";
import QuestionContextProvider from "./store/context/questionnaire-context";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./styles/general.css";
import AddBusiness from "./AddBusiness";
import BusinessQuiz from "./BusinessQuiz";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddReward from "./AddReward";
import Success from "./Success";

let theme = createTheme({
  palette: {
    primary: {
      main: '#ff7629',
    },
    secondary: {
      main: '#000000'
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <QuestionContextProvider>
          <Router>
            {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
            <Routes>
              <Route path="/quiz" element={<Questionnaire/>}/>
              <Route path="/addBusiness" element={<AddBusiness />}/>
              <Route path="/addReward" element={<AddReward />}/>
              <Route path="/bizQuiz" element={<BusinessQuiz />}/>
              <Route path="/results" element={<Recommendations/>}/>
              <Route path="/complete" element={<Complete />}/>
              <Route path="/success" element={<Success />} />
              <Route path="/" element={<NameEmail/>} />
              <Route path="*" element={<Navigate to="/" replace />}/>
            </Routes>
          </Router>
        </QuestionContextProvider>
      </ThemeProvider>
    </>
  );
}

export default App;

import React from 'react';
import { secondaryButtonStyles } from '../styles/mui_custom';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const Reward = (props) => {
  const {data} = props; // An object of recommendation data

  if (!data) {
    return <div />;
  }

  const LocationButton = styled(Button)(() => (secondaryButtonStyles));

  const locationUrl = data.location ? encodeURI(`https://maps.google.com/?q=${data.location}`) : '';

  return (
    <div className="reward">
      <p className="reward_business_category">
        {data.category.name}
      </p>
      <p className="reward_business_name">
        {data.name}
      </p>
      {data.location && (
        <div className="reward_business_location">
          <LocationButton
            href={locationUrl}
          >
            Open Maps
          </LocationButton>
        </div>
      )}
      {data.reward.imageUrl ? (
        <img src={data.reward.imageUrl} className="reward_image" alt={data.reward.description} />
      ) : (
        <div className="reward_text">
          <p className="reward_description">
            {data.reward.description}
          </p>
          <p className="reward_instructions">
            1. Save image on mobile device.<br />
            2. Show image at recommended space.
          </p>
        </div>
      )}
    </div>
  );
};

export default Reward;

export const submitButtonStyles = {
  color: 'white',
  backgroundColor: '#ff7629',
  textTransform: 'none',
  fontSize: '1rem',
  paddingRight: '1rem',
  paddingLeft: '1rem',
  border: '#ff7629 solid 1px',
  borderRadius: 6,
  boxShadow: '-5px 5px 0 black',
  '&:hover': {
    backgroundColor: 'white',
    color: '#ff7629'
  },
};

export const secondaryButtonStyles = {
  color: 'white',
  backgroundColor: '#000000',
  textTransform: 'none',
  fontSize: '1rem',
  paddingRight: '1rem',
  paddingLeft: '1rem',
  border: '#000000 solid 1px',
  borderRadius: 6,
  '&:hover': {
    backgroundColor: 'white',
    color: '#000000'
  },
};

import React, { useContext, useRef, useState } from 'react';
import TextField from "@mui/material/TextField";
import { useNavigate } from 'react-router-dom';
import { testEmail } from '../services/validation';
import { saveConsumerData } from '../services/consumer';
import { QuestionContext } from '../store/context/questionnaire-context';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';
import { submitButtonStyles } from '../styles/mui_custom';
import ReCAPTCHA from 'react-google-recaptcha';
import { checkDevMode } from '../services/functions';

function NameEmail() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState({
    name: false,
    email: false,
  });

  const reCaptchaRef = useRef(null);

  const qContext = useContext(QuestionContext);
  const {updateUserId} = qContext;

  const navigate = useNavigate();

  // Determine if we are on the intended domain for production
  const devMode = checkDevMode();

  const handleName = (value) => {
    const isValid = value.trim() !== '';
    if (isValid && error.name) {
      setError((prevState) => ({
        ...prevState,
        name: false,
      }));
    }
    setName(value);
  }

  const handleEmail = (value) => {
    const isValid = testEmail(value.trim());
    if (isValid && error.email) {
      setError((prevState) => ({
        ...prevState,
        email: false,
      }));
    }
    setEmail(value);
  }

  const handleReCaptchaUpdate = (value) => {
    if (value === null) {
      console.log('ReCAPTCHA expired.');
    } else {
      console.log('ReCAPTCHA value updated.');
    }
  }

  const handleReCaptchaError = () => {
    // TODO: Replace w/branded Material UI alert toast
    window.alert("CAPTCHA error. Please check your network connection and try again.");
  };

  async function onSubmit() {
    // Validate inputs
    const nameTrimmed = name.trim();
    const emailTrimmed = email.trim();
    if (!nameTrimmed || !testEmail(emailTrimmed)) {
      setError({
        name: !nameTrimmed,
        email: !testEmail(emailTrimmed),
      });
      return;
    }

    // Attempt to acquire reCAPTCHA token
    const token = await reCaptchaRef.current?.executeAsync();
    if (!token) {
      // Error handling handled by callbacks
      return;
    }

    // Send data to server
    setIsSubmitting(true);

    const params = {
      token,
      name: nameTrimmed,
      email: emailTrimmed,
      devMode,
    };

    saveConsumerData(params)
    .then((data) => {
      if (data) {
        const {id} = data;
        updateUserId(id);
        navigate("/quiz");
        window.scrollTo(0, 0);
      }
      setIsSubmitting(false);
    });
  }

  const SubmitButton = styled(LoadingButton)(() => (submitButtonStyles));

  const submitSpinner = (
    <CircularProgress 
      size={20}
      sx={{
        color: 'white'
      }}
    />
  );

  // Build React reCaptcha element
  // TODO: If navigating to this view using a back button,
  // reCAPTCHA is not visible; perhaps add token to context or find
  // another method of dealing with this
  const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
  const testKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_TEST_KEY;
  const reCaptcha = <ReCAPTCHA
    ref={reCaptchaRef}
    sitekey={devMode ? testKey : siteKey}
    size="invisible"
    badge="bottomright"
    onChange={handleReCaptchaUpdate}
    onErrored={handleReCaptchaError}
  />

  return (
    <div className="view_container">
      <div className="content_container">
        <p className="signup_heading">
          Sign Up
        </p>
        <div className="form_container">
          <div className="form_item">
            <TextField 
              id="name"
              label="Name"
              variant="standard"
              error={error.name}
              helperText={error.name ? 'Please enter your name.' : ' '}
              value={name}
              onChange={(e) => handleName(e.target.value)}
              fullWidth
              margin="none"
              InputLabelProps={{
                shrink: true,
                sx: {
                  textTransform: 'uppercase',
                  color: '#ff7629'
                }
              }}
            />
          </div>
          <div className="form_item">
            <TextField
              id="email"
              label="Email"
              variant="standard"
              error={error.email}
              helperText={error.email ? 'Please enter a valid email.' : ' '}
              value={email}
              onChange={(e) => handleEmail(e.target.value)}
              fullWidth
              margin="none"
              InputLabelProps={{
                shrink: true,
                sx: {
                  textTransform: 'uppercase',
                  color: '#ff7629'
                }
              }}
            />
          </div>
        </div>
        <div className="submit_button_container">
          <SubmitButton
            onClick={onSubmit}
            loading={isSubmitting}
            loadingIndicator={submitSpinner}
            disabled={isSubmitting}
          >
            Next
          </SubmitButton>
        </div>
        {reCaptcha}
      </div>
    </div>
  );
}

export default NameEmail;

import {getFunctions, httpsCallable} from 'firebase/functions';

/**
 * Save consumer user name and email to database
 * Returns { id: string } or null if an error occurs
 */
export const saveConsumerData = async ({
  token, name, email, devMode
}) => {
  const functions = getFunctions();
  const saveConsumerUserData = httpsCallable(functions, 'saveConsumerUserData');

  try {
    const {data} = await saveConsumerUserData({
      token, name, email, devMode
    });
    return data;
  } catch (error) {
    console.log('Failed to save user data: ', error);
    // TODO: Move to branded error message using Material UI
    window.alert('Could not save data at this time.  Please check your network connection or try again later.');
    return null;
  }
};

import { useContext } from "react";
import { QuestionContext } from "../store/context/questionnaire-context";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Checkbox, FormGroup, FormHelperText } from "@mui/material";

function Question(props) {
  const {number, question, answers, error, onAnswerChanged, bizQuiz} = props;
  const questionId = props.id;

  const qContext = useContext(QuestionContext);
  const {updateAnswer} = qContext;
  const savedAnswers = qContext.answers;

  // Determine the current value for this question saved in context
  const savedAnswer = savedAnswers.find((answer) => {
    return answer.qid === questionId;
  });
  const currentAnswerId = savedAnswer.aid;

  const handleSelect = (id, checked) => {
    const update = checked ? id : '';
    updateAnswer(questionId, update);
    if (onAnswerChanged) {
      onAnswerChanged({
        qid: questionId,
        aid: update,
      });
    }
  }

  return (
    <>
      <div className="question_container">
        {!bizQuiz && (
          <div className="question_number_label">
            {"Question #" + number}
          </div>
        )}
        <FormControl
          error={error}
        >
          <FormLabel 
            id={`question-${number}`}
            sx={{
              color: '#7d7d7d',
              marginBottom: '0.3rem',
            }}
          >
            {question}
          </FormLabel>
          <FormGroup>
            {answers.map((answer) => {
              const {id, text} = answer;
              return (
                <FormControlLabel
                  key={id}
                  value={id}
                  control={<Checkbox 
                    checked={id === currentAnswerId}
                    onChange={(e) => handleSelect(id, e.target.checked)}
                    sx={{
                      color: '#ff7629',
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  />}
                  label={text}
                  sx={{
                    alignItems: 'flex-start',
                    color: '#7d7d7d',
                  }}
                />
              );
            })}
          </FormGroup>
          <FormHelperText>
            {error ? 'Please choose an answer.' : ' '}
          </FormHelperText>
        </FormControl>
      </div>
    </>
  );
}

export default Question;

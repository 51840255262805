import React from 'react';

function Success () {
  return (
    <div className="view_container">
      <div className="content_container">
        <p className="completion_heading">
          Success!
        </p>
      </div>
    </div>
  );
};

export default Success;
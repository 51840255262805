import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QuestionContext } from '../store/context/questionnaire-context';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, MenuItem, TextField } from '@mui/material';
import { submitButtonStyles } from '../styles/mui_custom';
import ReCAPTCHA from 'react-google-recaptcha';
import { checkDevMode } from '../services/functions';
import { inputLabelProps } from '../services/data';
import { getQuizData, submitBusinessQuizData } from '../services/quiz';
import { verifyBusinessByEmailAndCode } from '../services/business';
import { getYears } from '../services/functions';
import { brandAesthetics, primaryBrandColors } from '../services/data';
import Question from '../Question';

function BusinessQuiz() {
  const [quizData, setQuizData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('');
  const [businessCategories, setBusinessCategories] = useState(null);
  const [brandCategory, setBrandCategory] = useState('');
  const [yearEstablished, setYearEstablished] = useState('');
  const [brandColor, setBrandColor] = useState('');
  const [brandAesthetic, setBrandAesthetic] = useState([]);
  const [error, setError] = useState({
    name: false,
    brandCategory: false,
    yearEstablished: false,
    brandColor: false,
    brandAesthetic: false,
  });

  const reCaptchaRef = useRef(null);

  const qContext = useContext(QuestionContext);
  const {userId, initializeAnswers, updateUserId} = qContext;
  const savedAnswers = qContext.answers;

  const navigate = useNavigate();

  // Determine if we are on the intended domain for production
  const devMode = checkDevMode();

  // Acquire the email and temp code from the URL params
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const email = urlParams.get('email');
  const code = urlParams.get('code');

  // Load questionnnaire data on mount
  useEffect(() => {
    // Verify business and acquire business data
    verifyBusinessByEmailAndCode({email, code})
    .then((businessResponse) => {
      if (businessResponse?.businessData && businessResponse?.businessCategories) {
        // Update business and category data
        setBusinessCategories(businessResponse.businessCategories);
        setName(businessResponse.businessData.name || '');
        setBrandCategory(businessResponse.businessData.categoryId || null);
        updateUserId(businessResponse.businessData.id || null);

        // Fetch quiz from database
        const quizType = 'Business';
        getQuizData({quizType})
        .then((quizResponse) => {
          if (quizResponse) {
            // Update context
            const initialAnswers = quizResponse.map((question) => {
              return {qid: question.id, aid: ''}
            });
            initializeAnswers(initialAnswers);
          }
          setQuizData(quizResponse);
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    });
  }, []); // eslint-disable-line

  const handleName = (value) => {
    const isValid = value.trim() !== '';
    if (isValid && error.name) {
      setError((prevState) => ({
        ...prevState,
        name: false,
      }));
    }
    setName(value);
  }

  const handleBrandCategory = (id) => {
    if (id && error.brandCategory) {
      setError((prevState) => ({
        ...prevState,
        brandCategory: false,
      }));
    }
    setBrandCategory(id);
  }

  const handleYearEstablished = (value) => {
    if (value && error.yearEstablished) {
      setError((prevState) => ({
        ...prevState,
        yearEstablished: false,
      }));
    }
    setYearEstablished(value);
  }

  const handleBrandColor = (value) => {
    if (value && error.brandColor) {
      setError((prevState) => ({
        ...prevState,
        brandColor: false,
      }));
    }
    setBrandColor(value);
  }

  const handleBrandAesthetic = (aesthetic, checked) => {
    let aestheticUpdate = [
      ...brandAesthetic,
    ];

    if (checked && !brandAesthetic.includes(aesthetic)) {
      // Add this value if not already in the array
      aestheticUpdate.push(aesthetic);
      setBrandAesthetic(aestheticUpdate);
    }

    if (!checked && brandAesthetic.includes(aesthetic)) {
      // Remove this value if currently in the array
      const valIndex = aestheticUpdate.findIndex((val) => {
        return val === aesthetic;
      });

      if (valIndex > -1) {
        aestheticUpdate.splice(valIndex, 1);
      }

      setBrandAesthetic(aestheticUpdate);
    }

    if (aestheticUpdate.length === brandAesthetics.length && error.brandAesthetic) {
      // Remove any error state if all boxes are checked
      setError((prevState) => ({
        ...prevState,
        brandAesthetic: false,
      }));
    }
  }

  const onAnswerChanged = ({qid, aid}) => {
    if (aid && error[qid]) {
      setError((prevState) => {
        const errorUpdate = prevState;
        errorUpdate[qid] = false;
        return errorUpdate;
      });
    }
  };

  const handleReCaptchaUpdate = (value) => {
    if (value === null) {
      console.log('ReCAPTCHA expired.');
    } else {
      console.log('ReCAPTCHA value updated.');
    }
  }

  const handleReCaptchaError = () => {
    // TODO: Replace w/branded Material UI alert toast
    window.alert("CAPTCHA error. Please check your network connection and try again.");
  };

  async function onSubmit() {
    // Validate inputs
    const nameTrimmed = name.trim();

    let errorsExist = false;

    if (
      !nameTrimmed ||
      !yearEstablished ||
      !brandCategory ||
      !brandColor ||
      brandAesthetic.length !== brandAesthetics.length
    ) {
      setError({
        name: !nameTrimmed,
        yearEstablished: !yearEstablished,
        brandCategory: !brandCategory,
        brandColor: !brandColor,
        brandAesthetic: brandAesthetic.length !== brandAesthetics.length,
      });
      errorsExist = true;
    }

    const answerErrors = {};
    savedAnswers.forEach((answer) => {
      if (!answer.aid) {
        answerErrors[answer.qid] = true;
        if (!errorsExist) {
          errorsExist = true;
        }
      }
    });

    setError((prevState) => ({
      ...prevState,
      ...answerErrors,
    }));

    if (errorsExist) {
      window.alert("Please complete each item before continuing.");
      return;
    }

    // Attempt to acquire reCAPTCHA token
    const token = await reCaptchaRef.current?.executeAsync();
    if (!token) {
      // Error handling handled by callbacks
      return;
    }

    // Send data to server
    setIsSubmitting(true);

    const params = {
      token,
      userId,
      name: nameTrimmed,
      categoryId: brandCategory,
      yearEstablished: parseInt(yearEstablished),
      brandColor,
      brandAesthetic,
      responses: savedAnswers,
      devMode,
    };

    submitBusinessQuizData(params)
    .then((data) => {
      if (data) {
        navigate("/complete");
        window.scrollTo(0, 0);
      }
      setIsSubmitting(false);
    });
  }

  const SubmitButton = styled(LoadingButton)(() => (submitButtonStyles));

  const loadingSpinner = (
    <CircularProgress 
      size={30}
      sx={{
        color: '#ff7629'
      }}
    />
  );

  const submitSpinner = (
    <CircularProgress 
      size={20}
      sx={{
        color: 'white'
      }}
    />
  );

  // Build React reCaptcha element
  // TODO: If navigating to this view using a back button,
  // reCAPTCHA is not visible; perhaps add token to context or find
  // another method of dealing with this
  const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
  const testKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_TEST_KEY;
  const reCaptcha = <ReCAPTCHA
    ref={reCaptchaRef}
    sitekey={devMode ? testKey : siteKey}
    size="invisible"
    badge="bottomright"
    onChange={handleReCaptchaUpdate}
    onErrored={handleReCaptchaError}
  />

  const validYears = getYears(1900);

  return (
    <div className="view_container">
      <div className="content_container">
        <p className="questionnaire_heading">
          Welcome to<br />the Biz Quiz
        </p>
        {isLoading && (
          <div className="loading_indicator">
            {loadingSpinner}
          </div>
        )}
        {!isLoading && (!businessCategories || !quizData) && (
          <p className="loading_message">
            Quiz unavailable; please try again later.
          </p>
        )}
        {!isLoading && businessCategories && quizData && (
          <>
            <div className="form_container">
              <div className="form_item">
                <TextField 
                  id="name"
                  label="Brand Name"
                  variant="standard"
                  error={error.name}
                  helperText={error.name ? 'Please enter your legal business name.' : ' '}
                  value={name}
                  onChange={(e) => handleName(e.target.value)}
                  fullWidth
                  margin="none"
                  InputLabelProps={inputLabelProps}
                />
              </div>
              <div className="form_item">
                <TextField
                  id="brandCategory"
                  label="Brand category"
                  variant="standard"
                  select
                  error={error.brandCategory}
                  helperText={error.brandCategory ? 'Please select a brand category.' : ' '}
                  value={brandCategory}
                  onChange={(e) => handleBrandCategory(e.target.value)}
                  fullWidth
                  InputLabelProps={inputLabelProps}
                >
                  {businessCategories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="form_item">
                <TextField
                  id="yearEstablished"
                  label="Year established"
                  variant="standard"
                  select
                  error={error.yearEstablished}
                  helperText={error.yearEstablished ? 'Please select the year your brand was established.' : ' '}
                  value={yearEstablished}
                  onChange={(e) => handleYearEstablished(e.target.value)}
                  fullWidth
                  InputLabelProps={inputLabelProps}
                >
                  {validYears.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="form_item">
                <TextField
                  id="brandColor"
                  label="Primary brand color"
                  variant="standard"
                  select
                  error={error.brandColor}
                  helperText={error.brandColor ? 'Please select a primary brand color.' : ' '}
                  value={brandColor}
                  onChange={(e) => handleBrandColor(e.target.value)}
                  fullWidth
                  InputLabelProps={inputLabelProps}
                >
                  {primaryBrandColors.map((color) => (
                    <MenuItem key={color} value={color}>
                      {color}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="question_container">
                <FormControl
                  error={error.brandAesthetic}
                >
                  <FormLabel 
                    id="brandAesthetic"
                    sx={{
                      color: '#7d7d7d',
                      marginBottom: '0.3rem',
                    }}
                  >
                    Rank the order in which each label best fits the aesthetic of your brand's space.
                  </FormLabel>
                  <FormGroup>
                    {brandAesthetics.map((aesthetic) => (
                      <FormControlLabel
                        key={aesthetic}
                        control={
                          <Checkbox
                            checked={brandAesthetic.includes(aesthetic)}
                            onChange={(e) => handleBrandAesthetic(aesthetic, e.target.checked)}
                            sx={{
                              color: '#ff7629',
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          />
                        }
                        label={aesthetic}
                        sx={{
                          alignItems: 'flex-start',
                          color: '#7d7d7d',
                        }}
                      />
                    ))}
                  </FormGroup>
                  <FormHelperText>
                    {error.brandAesthetic ? 'Please choose the order of your preference.' : ' '}
                  </FormHelperText>
                </FormControl>
              </div>
              {quizData.map((question) => {
                return <Question
                  id={question.id}
                  key={question.id}
                  number={question.order}
                  question={question.text}
                  answers={question.answers}
                  error={error[question.id]}
                  onAnswerChanged={onAnswerChanged}
                  bizQuiz
                />
              })}
            </div>
            <div className="submit_button_container">
              <SubmitButton
                onClick={onSubmit}
                loading={isSubmitting}
                loadingIndicator={submitSpinner}
                disabled={isSubmitting}
              >
                Submit
              </SubmitButton>
            </div>
          </>
        )}
      </div>
      {reCaptcha}
    </div>
  );
}

export default BusinessQuiz;
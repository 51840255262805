import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { QuestionContext } from '../store/context/questionnaire-context';

function Complete () {
  const qContext = useContext(QuestionContext);
  const {userId} = qContext;

  const navigate = useNavigate();

  useEffect(() => {
    // If no user ID is saved in state, redirect to the initial view
    if (!userId) {
      navigate('/');
      return;
    }
  }, []); // eslint-disable-line

  return (
    <div className="view_container">
      <div className="content_container">
        <p className="completion_heading">
          Thank you!
        </p>
      </div>
    </div>
  );
};

export default Complete;
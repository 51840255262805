import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveRewardData } from '../services/business';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress, TextField } from '@mui/material';
import { submitButtonStyles } from '../styles/mui_custom';
import ReCAPTCHA from 'react-google-recaptcha';
import { checkDevMode } from '../services/functions';
import { inputLabelProps } from '../services/data';
import { testEmail } from '../services/validation';

function AddReward() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState({
    description: false,
    email: false,
  });

  const reCaptchaRef = useRef(null);

  const navigate = useNavigate();

  // Determine if we are on the intended domain for production
  const devMode = checkDevMode();

  const handleDescription = (value) => {
    const isValid = value.trim() !== '';
    if (isValid && error.name) {
      setError((prevState) => ({
        ...prevState,
        description: false,
      }));
    }
    setDescription(value);
  }

  const handleEmail = (value) => {
    const isValid = testEmail(value.trim());
    if (isValid && error.email) {
      setError((prevState) => ({
        ...prevState,
        email: false,
      }));
    }
    setEmail(value);
  }

  const handleReCaptchaUpdate = (value) => {
    if (value === null) {
      console.log('ReCAPTCHA expired.');
    } else {
      console.log('ReCAPTCHA value updated.');
    }
  }

  const handleReCaptchaError = () => {
    // TODO: Replace w/branded Material UI alert toast
    window.alert("CAPTCHA error. Please check your network connection and try again.");
  };

  async function onSubmit() {
    // Validate inputs
    const descriptionTrimmed = description.trim();
    const emailTrimmed = email.trim();
    if (!descriptionTrimmed || !testEmail(emailTrimmed)) {
      setError({
        description: !descriptionTrimmed,
        email: !testEmail(emailTrimmed),
      });
      return;
    }

    // Attempt to acquire reCAPTCHA token
    const token = await reCaptchaRef.current?.executeAsync();
    if (!token) {
      // Error handling handled by callbacks
      return;
    }

    // Send data to server
    setIsSubmitting(true);

    const params = {
      token,
      description: descriptionTrimmed,
      email: emailTrimmed,
      imageUrl: imageUrl.trim(),
      devMode,
    };

    saveRewardData(params)
    .then((data) => {
      if (data?.success) {
        navigate("/success");
        window.scrollTo(0, 0);
      }
      setIsSubmitting(false);
    });
  }

  const SubmitButton = styled(LoadingButton)(() => (submitButtonStyles));

  const submitSpinner = (
    <CircularProgress 
      size={20}
      sx={{
        color: 'white'
      }}
    />
  );

  // Build React reCaptcha element
  // TODO: If navigating to this view using a back button,
  // reCAPTCHA is not visible; perhaps add token to context or find
  // another method of dealing with this
  const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
  const testKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_TEST_KEY;
  const reCaptcha = <ReCAPTCHA
    ref={reCaptchaRef}
    sitekey={devMode ? testKey : siteKey}
    size="invisible"
    badge="bottomright"
    onChange={handleReCaptchaUpdate}
    onErrored={handleReCaptchaError}
  />

  return (
    <div className="view_container">
      <div className="content_container">
        <p className="signup_heading">
          Add a Reward
        </p>
        <div className="form_container">
          <div className="form_item">
            <TextField 
              id="description"
              label="Short Description"
              variant="standard"
              helperText={error.description ? 'Please enter a short description for this reward.' : ' '}
              value={description}
              onChange={(e) => handleDescription(e.target.value)}
              fullWidth
              margin="none"
              InputLabelProps={inputLabelProps}
            />
          </div>
          <div className="form_item">
            <TextField
              id="email"
              label="Business Contact Email"
              variant="standard"
              error={error.email}
              helperText={error.email ? 'Please enter a valid email.' : ' '}
              value={email}
              onChange={(e) => handleEmail(e.target.value)}
              fullWidth
              margin="none"
              InputLabelProps={inputLabelProps}
            />
          </div>
          <div className="form_item">
            <TextField 
              id="imageUrl"
              label="Image URL"
              variant="standard"
              helperText=" "
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              fullWidth
              margin="none"
              InputLabelProps={inputLabelProps}
            />
          </div>
        </div>
        <div className="submit_button_container">
          <SubmitButton
            onClick={onSubmit}
            loading={isSubmitting}
            loadingIndicator={submitSpinner}
            disabled={isSubmitting}
          >
            Submit
          </SubmitButton>
        </div>
      </div>
      {reCaptcha}
    </div>
  );
}

export default AddReward;
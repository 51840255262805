import {getFunctions, httpsCallable} from 'firebase/functions';

/**
 * Fetch quiz questions from the database
 * quizType is an optional parameter; if omitted, we assume consumer quiz data
 * Returns an array of question data or null if an error occurs
 */
export const getQuizData = async ({
  quizType,
}) => {
  const functions = getFunctions();
  const getUserQuizQuestions = httpsCallable(functions, 'getUserQuizQuestions');

  try {
    const {data} = await getUserQuizQuestions({quizType});
    return data;
  } catch (error) {
    console.log('Failed to acquire quiz data: ', error);
    // TODO: Move to branded error message using Material UI
    window.alert('Could not load quiz data at this time.  Please check your network connection or try again later.');
    return null;
  }
};

/**
 * Submit consumer quiz questions for a specific user ID
 * Response data should be an array of { qid: string, aid: string } objects
 * Returns null if an error returns
 */
export const submitConsumerQuizData = async ({
  userId, 
  responses,
}) => {
  const functions = getFunctions();
  const processConsumerQuiz = httpsCallable(functions, 'processConsumerQuiz');

  try {
    const payload = {
      userId,
      responses,
    };
    const {data} = await processConsumerQuiz(payload);
    return data;
  } catch (error) {
    console.log('Failed to submit quiz data: ', error);
    // TODO: Move to branded error message using Material UI
    window.alert('Could not submit quiz data at this time.  Please check your network connection or try again later.');
    return null;
  }
};

/**
 * Submit business quiz ("biz quiz") data for a specific user ID
 */
export const submitBusinessQuizData = async ({
  token,
  userId,
  name,
  categoryId,
  yearEstablished,
  brandColor,
  brandAesthetic,
  responses,
  devMode,
}) => {
  const functions = getFunctions();
  const processBusinessQuiz = httpsCallable(functions, 'processBusinessQuiz');

  try {
    const payload = {
      token,
      userId,
      name,
      categoryId,
      yearEstablished,
      brandColor,
      brandAesthetic,
      responses,
      devMode
    };
    const {data} = await processBusinessQuiz(payload);
    return data;
  } catch (error) {
    console.log('Failed to submit quiz data: ', error);
    // TODO: Move to branded error message using Material UI
    window.alert('Could not submit quiz data at this time.  Please check your network connection or try again later.');
    return null;
  }
};
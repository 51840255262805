import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBusinessCategories, saveBusinessData } from '../services/business';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress, MenuItem, TextField } from '@mui/material';
import { submitButtonStyles } from '../styles/mui_custom';
import ReCAPTCHA from 'react-google-recaptcha';
import { checkDevMode } from '../services/functions';
import { inputLabelProps } from '../services/data';
import { testEmail } from '../services/validation';

function AddBusiness() {
  const [businessCategories, setBusinessCategories] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [brandCategory, setBrandCategory] = useState('');
  const [description, setDescription] = useState('');
  const [featuredImageUrl, setFeaturedImageUrl] = useState('');
  const [location, setLocation] = useState('');
  const [error, setError] = useState({
    name: false,
    email: false,
    brandCategory: false,
  });

  const reCaptchaRef = useRef(null);

  const navigate = useNavigate();

  // Determine if we are on the intended domain for production
  const devMode = checkDevMode();

  // Load business categories on mount
  useEffect(() => {
    // Fetch data from database
    getBusinessCategories()
    .then((data) => {
      if (data) {
        setBusinessCategories(data);
      }
      setIsLoading(false);
    });
  }, []); // eslint-disable-line

  const handleName = (value) => {
    const isValid = value.trim() !== '';
    if (isValid && error.name) {
      setError((prevState) => ({
        ...prevState,
        name: false,
      }));
    }
    setName(value);
  }

  const handleEmail = (value) => {
    const isValid = testEmail(value.trim());
    if (isValid && error.email) {
      setError((prevState) => ({
        ...prevState,
        email: false,
      }));
    }
    setEmail(value);
  }

  const handleBrandCategory = (id) => {
    if (id && error.brandCategory) {
      setError((prevState) => ({
        ...prevState,
        brandCategory: false,
      }));
    }
    setBrandCategory(id);
  }

  const handleReCaptchaUpdate = (value) => {
    if (value === null) {
      console.log('ReCAPTCHA expired.');
    } else {
      console.log('ReCAPTCHA value updated.');
    }
  }

  const handleReCaptchaError = () => {
    // TODO: Replace w/branded Material UI alert toast
    window.alert("CAPTCHA error. Please check your network connection and try again.");
  };

  async function onSubmit() {
    // Validate inputs
    const nameTrimmed = name.trim();
    const emailTrimmed = email.trim();
    if (!nameTrimmed || !testEmail(emailTrimmed) || !brandCategory) {
      setError({
        name: !nameTrimmed,
        email: !testEmail(emailTrimmed),
        brandCategory: !brandCategory,
      });
      return;
    }

    // Attempt to acquire reCAPTCHA token
    const token = await reCaptchaRef.current?.executeAsync();
    if (!token) {
      // Error handling handled by callbacks
      return;
    }

    // Send data to server
    setIsSubmitting(true);

    const params = {
      token,
      name: nameTrimmed,
      email: emailTrimmed,
      categoryId: brandCategory,
      featuredImageUrl: featuredImageUrl.trim(),
      description: description.trim(),
      location: location.trim(),
      devMode,
    };

    saveBusinessData(params)
    .then((data) => {
      if (data) {
        const {inviteUrl} = data;
        if (inviteUrl) {
          console.log(`Invite this business to take the biz quiz at this link: ${inviteUrl}`);
        }
        navigate("/success");
        window.scrollTo(0, 0);
      }
      setIsSubmitting(false);
    });
  }

  const SubmitButton = styled(LoadingButton)(() => (submitButtonStyles));

  const loadingSpinner = (
    <CircularProgress 
      size={30}
      sx={{
        color: '#ff7629'
      }}
    />
  );

  const submitSpinner = (
    <CircularProgress 
      size={20}
      sx={{
        color: 'white'
      }}
    />
  );

  // Build React reCaptcha element
  // TODO: If navigating to this view using a back button,
  // reCAPTCHA is not visible; perhaps add token to context or find
  // another method of dealing with this
  const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
  const testKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_TEST_KEY;
  const reCaptcha = <ReCAPTCHA
    ref={reCaptchaRef}
    sitekey={devMode ? testKey : siteKey}
    size="invisible"
    badge="bottomright"
    onChange={handleReCaptchaUpdate}
    onErrored={handleReCaptchaError}
  />

  return (
    <div className="view_container">
      <div className="content_container">
        <p className="signup_heading">
          Add a Business
        </p>
        {isLoading && (
          <div className="loading_indicator">
            {loadingSpinner}
          </div>
        )}
        {!isLoading && !businessCategories && (
          <p className="loading_message">
            Data unavailable; please try again later.
          </p>
        )}
        {!isLoading && businessCategories && (
          <>
            <div className="form_container">
              <div className="form_item">
                <TextField 
                  id="name"
                  label="Brand Name"
                  variant="standard"
                  error={error.name}
                  helperText={error.name ? 'Please enter your legal business name.' : ' '}
                  value={name}
                  onChange={(e) => handleName(e.target.value)}
                  fullWidth
                  margin="none"
                  InputLabelProps={inputLabelProps}
                />
              </div>
              <div className="form_item">
                <TextField
                  id="email"
                  label="Email"
                  variant="standard"
                  error={error.email}
                  helperText={error.email ? 'Please enter a valid email.' : ' '}
                  value={email}
                  onChange={(e) => handleEmail(e.target.value)}
                  fullWidth
                  margin="none"
                  InputLabelProps={inputLabelProps}
                />
              </div>
              <div className="form_item">
                <TextField
                  id="brandCategory"
                  label="Brand category"
                  variant="standard"
                  select
                  error={error.brandCategory}
                  helperText={error.brandCategory ? 'Please select a brand category.' : ' '}
                  value={brandCategory}
                  onChange={(e) => handleBrandCategory(e.target.value)}
                  fullWidth
                  InputLabelProps={inputLabelProps}
                >
                  {businessCategories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="form_item">
                <TextField 
                  id="featuredImageUrl"
                  label="Featured Image URL"
                  variant="standard"
                  helperText=" "
                  value={featuredImageUrl}
                  onChange={(e) => setFeaturedImageUrl(e.target.value)}
                  fullWidth
                  margin="none"
                  InputLabelProps={inputLabelProps}
                />
              </div>
              <div className="form_item">
                <TextField 
                  id="description"
                  label="Short Description"
                  variant="standard"
                  helperText=" "
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                  margin="none"
                  InputLabelProps={inputLabelProps}
                />
              </div>
              <div className="form_item">
                <TextField 
                  id="location"
                  label="Full Address"
                  variant="standard"
                  helperText=" "
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  fullWidth
                  margin="none"
                  InputLabelProps={inputLabelProps}
                />
              </div>
            </div>
            <div className="submit_button_container">
              <SubmitButton
                onClick={onSubmit}
                loading={isSubmitting}
                loadingIndicator={submitSpinner}
                disabled={isSubmitting}
              >
                Submit
              </SubmitButton>
            </div>
          </>
        )}
      </div>
      {reCaptcha}
    </div>
  );
}

export default AddBusiness;